import feathersClient, { makeServicePlugin, BaseModel } from '../feathers-client';
// eslint-disable-next-line import/named
import { genId, meta } from '../tools';

class File extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'File'

  // Define default properties here
  static instanceDefaults(data) {
    return {
      uuid: genId(`${data.projectId}:${data.ref}`, 'files'),
      name: '',
      ref: '',
      body: '',
      contentType: '',
      localPath: null,
      projectId: '',
      main: false,
      ...meta(),
    };
  }
}
const servicePath = 'files';
const servicePlugin = makeServicePlugin({
  Model: File,
  service: feathersClient.service(servicePath),
  servicePath,
});

const authUrl = process.env.VUE_APP_AUTH_URL || 'https://auth.thimble.io';

// Set up the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [
      async (context) => {
        const projectId = context.params.query.projectId || null;

        let query = '';

        if (projectId) {
          query = `?projectId=${projectId}`;
        }

        const resp = await fetch(`${authUrl}/api/ide/files${query}`, { credentials: 'include' });

        const { files } = await resp.json();

        return {
          ...context,
          result: files.map((file) => ({
            ...file,
            id: file.id,
            _id: file.id,
            uuid: file.id,
          })),
        };
      },
    ],
    get: [],
    create: [
      async (context) => {
        const resp = await fetch(`${authUrl}/api/ide/files`,
          {
            body: JSON.stringify(context.data),
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
          });

        const { file } = await resp.json();

        return {
          ...context,
          data: {
            ...context.data,
            _id: file.id || context.data._id,
            id: file.id || context.data.id,
            uuid: file.id || context.data.uuid,
          },
        };
      },
    ],
    update: [
      async (context) => {
        const resp = await fetch(`${authUrl}/api/ide/files`,
          {
            body: JSON.stringify(context.data),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'PATCH',
            credentials: 'include',
          });
        const { file } = await resp.json();

        return {
          ...context,
          data: {
            ...file,
            _id: file.id || context.data._id,
            id: file.id || context.data.id,
            uuid: file.id || context.data.uuid,
          },
        };
      },
    ],
    patch: [
      async (context) => {
        const resp = await fetch(`${authUrl}/api/ide/files`,
          {
            body: JSON.stringify(context.data),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'PATCH',
            credentials: 'include',
          });
        const { file } = await resp.json();

        return {
          ...context,
          data: {
            ...file,
            _id: file.id || context.data._id,
            id: file.id || context.data.id,
            uuid: file.id || context.data.uuid,
          },
        };
      },
    ],
    remove: [
      async (context) => {
        const fileId = context.id;
        await fetch(`${authUrl}/api/ide/files?id=${fileId}`,
          {
            body: JSON.stringify(context.data),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'DELETE',
            credentials: 'include',
          });

        return {
          ...context,
          result: [],

        };
      },
    ],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
